<template>
<n-alert
    v-if="marketplace.localization?.['marketplace/signals/my/info']"
    class="rounded-lg" type="warning" size="large">
    <div
        class="notes text-xs"
        v-html="marketplace.localization?.['marketplace/signals/my/info']">
    </div>
</n-alert>

<div v-if="!init" class="flex h-full justify-center items-center py-60">
    <n-spin size="small" />
</div>
<super-table
    v-else
    showSearch
    :filters="filters"
    :actions="actions"
    :pager="dataRef.pager"
    :refFilters="refFilters"
    :loading="loadings.table"
    :columns="dataRef.columns"
    :records="dataRef.records"
    :filtersChanged="filtersChanged"
    type="marketplace.avail.signals"
    columnsStorageName="__columns_marketplace-my-signals"
    @getData="getData"
    @doSomething="doSomething"
    @applyFilters="changeFilter"
    @reset-filters="resetFilters">
    <template #tableTitle>
        <div class="text-base text-left mb-2">
            {{ marketplace.localization?.['marketplace/signals/my/grid/title'] }}
            <span class="text-main font-semibold text-lg">{{ dataRef.stats.total }}</span>
        </div>
    </template>
</super-table>

<base-modal-desktop
    v-if="signalsDetails"
    v-model:show="showInfoModal"
    class="w-10/12">
    <template #headerContent>
        <div class="flex items-center text-sm">
            <strong class="text-main">"{{ signalsDetails.name }}"</strong>&nbsp;
            <span>{{ marketplace.localization?.['marketplace/signals/avail/dialog/cost'] }} {{ signalsDetails.price_amount }}</span>
            <rb-coin class="ml-2 w-4" :coin="currency(signalsDetails._price_symbol)" />
        </div>
    </template>
    <template #default>
        <section>
            <n-alert
                class="rounded-lg" type="info" size="large">
                <div
                    class="notes text-xs"
                    v-html="signalsDetails.info">
                </div>
            </n-alert>

            <super-table
                :filters="filtersSignalsDetails"
                :pager="signalsDetails.pager"
                :columns="signalsDetails.columns"
                :records="signalsDetails.records"
                columnsStorageName="__columns_marketplace-avail-signals-details"
                @getData="getSignalsDetails"
                @applyFilters="changeFilterDetails">
                <template #tableTitle>
                    <div class="text-base text-left mb-2">
                        {{ marketplace.localization?.['marketplace/signals/details/grid/title'] }}
                        <span class="text-main font-semibold text-lg">{{ signalsDetails.stats.total }}</span>
                    </div>
                </template>
            </super-table>
        </section>
    </template>
    <template #footer v-if="signalsDetails.subscription_type == 'paid'">
        <div class="flex items-center justify-end">
            <rb-checkbox
                :label="'- ' + marketplace.localization?.['marketplace/mentors/dialogs/license_agreement']"
                v-model:checked="iAcceptIt" />
            <n-button
                strong
                class="rounded-md text-white/90"
                :disabled="!iAcceptIt"
                :color="gl.mainColor"
                @click="onRebillSignal(signalsDetails.id)">
                {{ marketplace.localization?.['marketplace/signals/my/grid/actions/rebill'] }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// general
import general from './general';

// components
import RbCoin from '@components/rb-coin';
import RbCheckbox from '@components/rb-checkbox';
import SuperTable from '@components/super-table/desktop.vue';
import FilterExchanges from '@components/filters/exchanges';
import FilterButtonGroup from '@components/filters/button-group.vue';

// naive ui
import {
    NSpin,
    NCard,
    NAlert,
    NButton } from 'naive-ui';

export default {
    name: 'my-signals-desktop',
    components: {
        NSpin,
        NCard,
        NAlert,
        RbCoin,
        NButton,
        SuperTable,
        RbCheckbox,
        FilterExchanges,
        FilterButtonGroup,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>